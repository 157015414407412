.form-check {
  .form-check-sign{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
}

.form-check-radio .form-check-sign{
  // font-family: 'FontAwesome';
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  &::before{
    font-size: 22px;
    content: "\f111";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: absolute;
    opacity: .50;
    left: 5px;
    top: -5px;
  }
}
.form-check .form-check-sign:after{
  font-family: "Font Awesome 5 Free";
  top: 4px;
}
.form-check-radio .form-check-sign::after,.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
  font-family: "Font Awesome 5 Free";
  top: -1px;
}
.form-check-radio .form-check-sign::before, .form-check-radio input[type="radio"]:checked + .form-check-sign::before {
  font-family: "Font Awesome 5 Free";
  top: -1px;
}

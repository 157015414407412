.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

@media all and (min-width: 992px) {
  .sidebar .nav > li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

/* Nucleo Style */

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608cee;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em 0.2em;
  margin-bottom: 1em;
}

.demo-icons ul {
  padding-left: 0;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 20%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: 0.25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608cee;
  color: #efefef;
}

.demo-icons ul em {
  font-size: 12px;
}

.demo-icons ul em::before {
  content: "[";
}

.demo-icons ul em::after {
  content: "]";
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}

.forgotPass {
  text-align: right;
  margin-top: 15px;
}
.forgotPass a {
  color: #ff511a;
  font-size: 14px;
}
.intro h2 {
  font: normal normal 600 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #212740;
  opacity: 1;
  margin-bottom: 10px;
}
.panelLogin > .content {
  padding: 0 30px 30px;
  min-height: calc(100vh - 123px);
  margin-top: 93px;
}
.anEm {
  text-decoration: underline;
}
.introWrap {
  text-align: center;
}
.introdetail {
  margin-top: 20px;
}
.cardRow {
  margin-top: 130px !important;
}
.cardRow {
  margin: 0;
}
.alertNotify {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fbc658 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #fff;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.enqDetail {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 0px 6px #0000000f;
  border-radius: 15px;
  opacity: 1;
  padding: 20px;
  margin-top: 100px;
}
.enqRow {
  padding: 20px;
}
.enqHd {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.enqHdL h2 {
  margin-bottom: 0;
  font-size: 18px;
  letter-spacing: -0.43px;
  color: #1a2241;
  opacity: 1;
}
.enqHdR button {
  background: #62ab5a 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  color: #fff;
  padding: 6px 15px;
  border-radius: 4px;
}
.enqBody {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 0.5px solid #c4c4c4;
  border-bottom: 0.5px solid #c4c4c4;
  opacity: 1;
  margin-top: 10px;
}
.enqFooter {
  padding-top: 10px;
}
.enqFld span {
  color: #f15527;
}
.noteWrp textarea {
  border: 1px solid #dde2f1;
  opacity: 1;
  width: 100%;
  padding: 15px;
}
.noteWrp {
  padding: 20px;
  margin-top: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 0px 6px #0000000f;
  border-radius: 15px;
  opacity: 1;
}
.noteWrp label {
  display: block;
  font: normal normal medium 18px/18px Montserrat;
  letter-spacing: -0.43px;
  color: #1a2241;
  opacity: 1;
}
.ntBtn {
  background: #f15527 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #fff;
  padding: 5px 15px;
  border: none;
  margin-top: 15px;
}
.btnNote {
  text-align: right;
}
.badge {
  padding: 5px 5px !important;
}
.cardmt thead {
  border-bottom: 1px solid #dee2e6;
}
.badge-pending {
  background: #fccf71 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 5px 10px !important;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #1a2241;
}
.noteTp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon i {
  padding: 8px;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 1px solid;
}
.vfiedBtn {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 26px;
}
a.nmpt {
  color: #212529;
}
.serSingle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sersRt {
  display: flex;
  align-items: center;
  grid-gap: 60px;
  gap: 60px;
  width: 12%;
  justify-content: space-between;
}
.sersLeft {
  flex: 0 0 80%;
}
.serList {
  border: 1px solid #a6b6cf;
  border-radius: 6px;
}
.serList .serSingle {
  border-bottom: 1px solid #a6b6cf;
}
.serList .serSingle:last-child {
  border: none;
}
.serSingle {
  padding: 10px 15px;
}
.availSingle {
  display: flex;
  gap: 20px;
}
.availSingle p {
  margin-bottom: 5px;
}
.avcol {
  padding: 10px;
}
.avLoc span {
  display: block;
  margin-bottom: 5px;
}
.avLoc span i,
.avTea i {
  margin-right: 5px;
  color: #9598a2;
}
.avTea p {
  text-align: right;
}
.avTea {
  display: flex;
}
.avLoc.avcol {
  flex: 0 0 60%;
}
.avTime.avcol {
  flex: 0 0 180px;
}
.avTime p {
  position: relative;
  padding-left: 20px;
}
p.avmorning::before {
  position: absolute;
  width: 15px;
  height: 15px;
  content: "";
  background-color: #ff511a;
  left: 0;
  border-radius: 50%;
  top: 2px;
}
p.avafternoon::before {
  position: absolute;
  width: 15px;
  height: 15px;
  content: "";
  background-color: #3c5fbf;
  left: 0;
  border-radius: 50%;
  top: 2px;
}
.availSingle {
  border: 1px solid #a6b6cf;
  border-radius: 6px;
  opacity: 1;
  margin-bottom: 20px;
}
.bankDSingle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a6b6cf;
  border-radius: 6px;
  opacity: 1;
  padding: 15px;
  margin-bottom: 15px;
}
.bankDSingleError {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #f50505;
  border-radius: 6px;
  opacity: 1;
  padding: 15px;
  margin-bottom: 15px;
}
.bankDSingle h2 {
  font: normal normal bold 18px Montserrat;
  letter-spacing: -0.43px;
  color: #1a2241;
  opacity: 1;
  margin-bottom: 0;
}
.bankDSingleError h2 {
  font: normal normal bold 18px Montserrat;
  letter-spacing: -0.43px;
  color: #1a2241;
  opacity: 1;
  margin-bottom: 0;
}
.errMsg p {
  color: #707070;
  margin-bottom: 0;
}
.errMsg h2 {
  font-size: 20px;
  margin-bottom: 0;
}
.errMsg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  padding: 15px;
  line-height: 30px;
}
.typeSp {
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 10px;
}
.typeSp button {
  background: #4e5d9d 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  border: none;
  color: #fff;
  padding: 5px 15px;
  outline: 0;
}
.orStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.orsL,
.orsR {
  display: flex;
  align-items: center;
  gap: 10px;
}
.orsL label,
.orsR label {
  margin-bottom: 0;
}
.nmTTl {
  margin-bottom: 10px !important;
}
.cardFter {
  margin-top: 60px;
}
.button-container {
  margin-top: 20px;
}
.disItem {
  color: #616161;
}
.disItem .nav-link {
  color: #616161;
  opacity: 0.43;
}
.availabilityFlex {
  display: flex;
  flex: 0 0 100%;
}
.availabilityParent {
  flex: 0 0 40%;
}
.avDay {
  flex: 0 0 10%;
}
.load-bar {
  position: relative;
  width: 100%;
  height: 5px;
  background-color: #fdba2c;
}
.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.bar:nth-child(1) {
  background-color: #da4733;
  animation: loading 3s linear infinite;
}
.bar:nth-child(2) {
  background-color: #3a1510;
  animation: loading 3s linear 1s infinite;
}
.bar:nth-child(3) {
  background-color: #fdba2c;
  animation: loading 3s linear 2s infinite;
}
@keyframes loading {
  from {
    left: 0;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
.spinner {
  width: 15px;
  height: 15px;
  margin: 2px auto;
  border: 2px solid rgba(67, 67, 67, 0.1);
  border-left: 2px solid #f00;
  border-right: 2px solid #f00;
  animation: spinner 1s linear infinite forwards;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.word_break {
  word-break: break-all !important;
}
.react-toast-notifications__container {
  z-index: 999999 !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f15527;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
span.badge-primary-blue {
  background-color: #03A9F4;
}
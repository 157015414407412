@media screen and (max-width: 991px){
  .navbar-nav{
    .nav-link{
      i.fa,
      i.fal,
      i.fas,
      i.fab,
      i.far,
      i.nc-icon{
        opacity: .5;
      }
    }
  }
  .navbar .dropdown.show .dropdown-menu{
    border: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: none;
    width: auto;
    margin: 0 1rem;
    box-shadow: none;
    position: static!important;
    padding-left: 10px;
    display: block!important;
    -webkit-transform: translateZ(0)!important;
    transform: translateZ(0)!important;
    background: inherit;
    &:before,&:after{
      display: none;
    }
  }
  .navbar.bg-dark .dropdown.show .dropdown-menu a{
    color: $white-color;
  }
}

@media screen and (min-width: 992px){

  .sidebar{
    .sidebar-wrapper{
      li.active{
        > a:not([data-toggle="collapse"]){
          &:before{
            border-right: 18px solid $medium-gray;
            border-top: 18px solid transparent;
            border-bottom: 18px solid transparent;
            right: -15px;
            top: 6px;
          }

          &:after{
            border-right: 17px solid $default-body-bg;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            right: -15px;
            top: 7px;
          }
        }
      }
    }
  }

}


.card-plain{
    background: transparent;
    box-shadow: none;

    .card-header,
    .card-footer{
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }

    &:not(.card-subcategories).card-body{
        padding-left: 0;
        padding-right: 0;
    }
}

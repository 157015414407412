// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
    background-color: $btn-color;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover,
    &.active:focus,
    &.active:hover,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle:focus,
    .show > &.dropdown-toggle:hover {
        background-color: $btn-states-color !important;
        color: $white-color !important;
        box-shadow: none !important;
    }

    &:not([data-action]):hover{
        box-shadow:  none;
    }

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $btn-color;
            border-color: $btn-color;
        }
    }

    // btn-neutral style
    @if $btn-color == $white-color{
        color: $primary-color;

        &.btn-danger{
            color: $danger-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $danger-states-color !important;
            }
        }

        &.btn-info{
            color: $info-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $info-states-color !important;
            }
        }

        &.btn-warning{
            color: $warning-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $warning-states-color !important;
            }
        }

        &.btn-success{
            color: $success-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $success-states-color !important;
            }
        }

        &.btn-default{
            color: $default-color !important;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $default-states-color !important;
            }
        }

        &.active,
        &:active,
        &:active:focus,
        &:active:hover,
        &.active:focus,
        &.active:hover,
        .show > &.dropdown-toggle,
        .show > &.dropdown-toggle:focus,
        .show > &.dropdown-toggle:hover {
            background-color: $white-color !important;
            color: $primary-states-color !important;
            box-shadow: none !important;
        }

        &:hover,
        &:focus{
            color: $primary-states-color !important;

            &:not(.nav-link){
                box-shadow: none;
            }

        }

    } @else {
        color: $white-color;
    }

    &.btn-simple{
        color: $btn-color;
        border-color: $btn-color;

        &:hover,
        &:focus,
        &:active{
            background-color: $transparent-bg;
            color: $btn-states-color;
            border-color: $btn-states-color;
            box-shadow: none;
        }
    }

    &.btn-link{
        color: $btn-color;

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            background-color: $transparent-bg;
            color: $btn-states-color;
            text-decoration: none;
            box-shadow: none;
        }
    }
}

@mixin btn-outline-styles($btn-color, $btn-states-color){
    background: $transparent-bg;
    border: 2px solid $btn-color !important;
    color: $btn-color;
    @include opacity(1);

    &:hover,
    &:focus,
    &:active,
    &:focus:active,
    &.active,
    .open > &.dropdown-toggle {
      background-color: $btn-color !important;
      color: $fill-font-color !important;
      border-color: $btn-color !important;
      .caret{
          border-top-color: $fill-font-color !important;
      }
    }

    .caret{
        border-top-color: $white-color !important;
    }

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
      &,
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: $transparent-bg !important;
        border-color: $btn-color !important;
      }
    }
}

@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border){
   font-size: $font-size;
   border-radius: $border;
   padding: $padding-vertical $padding-horizontal;

   &.btn-simple{
       padding: $padding-vertical - 1 $padding-horizontal - 1;
   }

}

@mixin rotate-180(){
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

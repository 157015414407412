a {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #c7d3e0 !important;
  border-color: #c7d3e0 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #c7d3e0 !important;
  border-color: #c7d3e0 !important;
}

.pagination-style {
  display: flex;
  list-style: none;
}

.pagination-prev {
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0px 0px;
  padding: 7px 12px;
  background-color: #fff;
}

.pagination-next {
  border: 1px solid #e8e8e8;
  border-radius: 0px 0px 4px 4px;
  padding: 7px 12px;
  background-color: #fff;
}

.pagination-btn {
  border: 1px solid #e8e8e8;
  border-radius: 0px;
  padding: 7px 12px;
  background-color: #fff;
}

.pagination-active {
  border: 1px solid #e8e8e8;
  border-radius: 0px;
  padding: 7px 12px;
  background-color: #fff;
  color: #f15527;
  font-weight: 700;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4e4e4e !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.imgw {
  width: 100%;
}

.imglogo {
  width: 50%;
}

.clinicimage {
  width: 65px;
  height: 65px;
}

.card-user .image {
  height: 100px !important;
}

.card-user .card-body {
  min-height: auto !important;
}

.card-user .author {
  margin-top: -150px !important;
}

.listdrop li {
  list-style: none;
}
.listdrop button {
  background-color: transparent;
  color: #616161;
  font-size: 20px;
}

.coloro {
  color: $primary-color !important;
}

.coloro-review {
  color: $primary-color !important;
  font-size: 24px;
}
.colorg-review {
  color: $default-color-opacity !important;
  font-size: 24px;
}

.card-user hr {
  margin: 5px 0px 15px !important;
}

.avatar-ad {
  width: 100px;
  height: 100px;
  border: 1px solid #ffffff;
  position: relative;
  border-radius: 100%;
}

.bluetext {
  color: $new-dark-blue;
}

.table th,
.table td {
  border-top: none !important;
  border-bottom: 1px solid #dee2e6;
}

.thikborder {
  border-bottom: 4px solid #dee2e6 !important;
}

.modeltitle {
  border: none !important;
}

.trianglestyle {
  color: #fccf71;
  font-size: 60px;
}
.list-group {
  text-align: left;
  list-style: none;
  color: #616161;
  line-height: 26px;
  span {
    color: #f15527;
  }
}
.nav-tabs {
  a {
    font-size: 16px;
    color: #5867a7;
    padding: 12px 38px !important;
    display: flex;

    @media screen and (max-width: 767px) {
      padding: 10px !important;
    }
  }
}
.nav-tabs .nav-item{
  font-weight: 600!important;

}
.badge-red_{
  background-color: #f44336f2;
  color: #fff;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {

  background-color: #f15527;
  color: #fff;
}
.medical-card {
  border-radius: 0px !important;
  .fz-18 {
    font-size: 18px;
  }
  p {
    font-size: 12px;
    color: #9598a2;
    span {
      color: #1a2241;
    }
  }
}
.service-card {
  overflow: hidden;

  .left-bx {
    width: 145px;
    height: 115px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .fz-18 {
    font-size: 19px;
  }
  .fz-14 {
    font-size: 12px;
  }
  .fz-16 {
    font-size: 15px;
  }
  p {
    font-size: 12px;
    color: #9598a2;
    span {
      color: #1a2241;
    }
  }
}

.formButtonfront {
  border:1px solid gray;
  border-radius:7%;
  // font-size:0.875;
  word-wrap: break-word;
  width: 100px; // only for IE8
  max-width: 40%;
 }
